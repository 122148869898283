import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createBrowserHistory } from 'history';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import {
  getCurrentLang,
  getAvailableLangs,
  getTranslates,
} from '../../services/translateSelector';
import globalConst from '../../constants';
import { loadAuth } from '../../redux/modules/auth';
import { getLangFromUrl } from '../../services/translateSelector';
import { getLocale, load as loadTranslates, } from '../../redux/modules/localization';
import { TranslationContext } from '../../contexts/TranslationContext';
import RouterContainer from './RouterContainer';
import NotFoundPage from '../NotFoundPage';

const mapStateToProps = (state) => ({
  currentLang: getCurrentLang(state),
  translates: getTranslates(state),
  langs: getAvailableLangs(state),
});
const LanguageContainer = (props) => {
  const {
    store,
    translates,
    currentLang,
    langs,
  } = props;
  const history = createBrowserHistory();

  const [loading, setLoading] = useState(true);
  const [locale, setLocale] = useState('');
  const [notFound, setNotFound] = useState(false);

  const translations = useMemo(() => ({
    tr: translates,
    currentLang,
    langs,
    setLocale
  }), [translates, currentLang]);

  useEffect(() => {
    async function initState() {
      await store.dispatch(loadAuth());

      // [1] - get lang from url for load translations
      const langFromUrl = getLangFromUrl(history.location.pathname);
      if (!langFromUrl) {
        const langFromStorage = getLocale();
        await store.dispatch(loadTranslates(langFromStorage));
        await setLocale(langFromStorage);
        setLoading(false);
        return;
      }

      // [3] - if langFromUrl right
      if (globalConst.LANGUAGES_LIST.includes(langFromUrl)) {
        await store.dispatch(loadTranslates(langFromUrl));
        await setLocale(langFromUrl);
        setLoading(false);
        return;
      }

      await store.dispatch(loadTranslates());
      setNotFound(true);
      setLoading(false);
    }

    initState();
  }, []);

  if (loading) {
    return null;
  }

  return (
    <TranslationContext.Provider value={translations}>
      <BrowserRouter>
        <Routes>
          {globalConst.LANGUAGES_ROUTES_LIST.map((path) => (
            <Route
              key={path}
              path={`${path}/*`}
              element={<RouterContainer />}
            />
          ))}
          {locale && <Route path="/" element={(<Navigate to={`/${locale}`} replace />)} />}
          {notFound && (
            <Route
              path="*"
              element={(
                <NotFoundPage
                  currentLang="en"
                />
              )}
            />
          )}
        </Routes>
      </BrowserRouter>
    </TranslationContext.Provider>
  );
};

LanguageContainer.propTypes = {
  store: PropTypes.object.isRequired,
  translates: PropTypes.object.isRequired,
  currentLang: PropTypes.string.isRequired,
  langs: PropTypes.array.isRequired,
};

export default connect(mapStateToProps)(LanguageContainer);
